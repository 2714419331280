import React, { useState, useEffect } from 'react'
import { Form, Col, Row, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import {
  getCartDetailsFromStorage,
  saveShippingMethodToStorage,
} from '../actions/cartActions'
import { Link } from 'react-router-dom'
import CheckoutSteps from '../components/CheckoutSteps'
import OrderSummary from '../components/OrderSummary'
import { FaBook, FaCheckCircle, FaCoins, FaTruck } from 'react-icons/fa'
import { v4 as uuidv4 } from 'uuid'
import Razorpay from 'razorpay'
import Loader from '../components/Loader'
import { addDiscountCode } from '../actions/discountCodeActions'
import Cookies from 'universal-cookie'
import Button from '@mui/material/Button'
import {
  userAddressList,
  deleteUserAddress,
  createGuestUserAddress,
} from '../actions/userActions'
import {
  createOrder,
  payOrder,
  postOrderPayment,
} from '../actions/orderActions'
import {
  saveDiscountPrice,
  saveDiscountCode,
  removeDiscountFromCart,
} from '../actions/cartActions'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import Container from '@mui/material/Container'
import { LazyLoadImage } from 'react-lazy-load-image-component'
const cookies = new Cookies()
function timeout(delay) {
  return new Promise((res) => setTimeout(res, delay))
}
const ShippingScreen = ({ history }) => {
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2)
  }
  const dispatch = useDispatch()
  const [email, setEmail] = useState(cookies.get('_cfx_email'))
  const [name, setName] = useState(cookies.get('_cfx_name'))
  const [disable, setDisabled] = useState(false)
  const [address, setAddress] = useState(cookies.get('_cfx_address'))
  const [address2, setAddress2] = useState(cookies.get('_cfx_address1'))
  const [city, setCity] = useState(cookies.get('_cfx_city'))
  const [postalCode, setPostalCode] = useState(cookies.get('_cfx_pin'))
  const [phone, setPhone] = useState(cookies.get('_cfx_phone'))
  const [state, setState] = useState(cookies.get('_cfx_state'))
  const [show, setShow] = useState(false)
  const [isBox, setIsBox] = useState(false)
  const [codCost, setCodCost] = useState('')
  const [payButtonClick, setPayButtonClick] = useState(false)
  const [payS, SetPayS] = useState(true)
  const [processingOrder, setProcessingOrder] = useState(false)
  const [couponCode, setCouponCode] = useState('')
  const [isCodeApplied, setIsCodeApplied] = useState(false)
  const [isCodeA, setIsCodeA] = useState(false)
  const [displayCartError, setDisplayCartError] = useState(false)
  const [couponCodeError, setCouponCodeError] = useState(false)
  const [isCodeB, setIsCodeB] = useState(false)
  const [discount, setDiscount] = useState({})
  const [nameError, setNameError] = useState(null)
  const [addressError, setAddressError] = useState(null)
  const [cityError, setCityError] = useState(null)
  const [stateError, setStateError] = useState(null)
  const [emailError, setEmailError] = useState(null)
  const [phoneError, setPhoneError] = useState(null)
  const [zipError, setZipError] = useState(null)
  const [codError, setCodError] = useState('')

  const guestUser = useSelector((state) => state.guestUser)
  const { user, success: guestSuccess, error: guestError } = guestUser

  const userCart = useSelector((state) => state.userCart)
  const { cart, loading: cartLoading, success: cartSuccess } = userCart

  const [paymentMethod, setPaymentMethod] = useState('')
  const orderCreate = useSelector((state) => state.orderCreate)
  const { order, success: orderSuccess, error } = orderCreate

  const discountCodeGet = useSelector((state) => state.discountCodeGet)
  const {
    discountCode,
    success: discountCodeSuccess,
    error: discountCodeError,
    loding: discountCodeLoading,
  } = discountCodeGet

  const removedDiscount = useSelector((state) => state.removeCartDiscount)
  const { discountCode: updatedCart, success: discountRemoveSuccess } =
    removedDiscount

  const setCartPaymentMethod = useSelector(
    (state) => state.setCartPaymentMethod
  )
  const {
    discountCode: updatedCartPaymentMethod,
    success: paymentmethodSuccess,
  } = setCartPaymentMethod

  const paymentROrder = useSelector((state) => state.orderPayRazor)
  const {
    orderPayDetails,
    success: paySuccess,
    error: payError,
    loading: payLoading,
  } = paymentROrder
  let country = 'India'
  var days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]
  var months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  var date = new Date()
  var deliveryDate = new Date(date.setTime(date.getTime() + 9 * 86400000))
  function isEmail(email) {
    var emailFormat = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
    if (email !== '' && email && email.match(emailFormat)) {
      return true
    }

    return false
  }
  const submitHandler = (e) => {
    e.preventDefault()
    setEmailError('')
    setNameError('')
    setAddressError('')
    setCityError('')
    setStateError('')
    setZipError('')
    if (isEmail(email)) {
      setEmailError('')
      if (name.length > 3) {
        if (address.length > 8) {
          if (city.length > 2) {
            if (state.length > 2) {
              var phonenoExp = /^\d{10}$/
              if (phonenoExp.test(phone)) {
                setPhoneError('')
                if (postalCode.length == 6) {
                  setZipError('')
                  if (cart.totalPrice > 1999 && e.target.value == 'cod') {
                    setCodError(
                      'Order above ₹1,999 is not eligible for Cash On Delivery'
                    )
                  } else {
                    setCodError('')
                    dispatch(
                      createGuestUserAddress({
                        name,
                        address,
                        address2,
                        city,
                        postalCode,
                        state,
                        country,
                        phone,
                        email,
                        cartId: cookies.get('_cid'),
                        pmethod: e.target.value,
                      })
                    )
                  }
                } else {
                  setZipError('Enter Valid ZipCode')
                  window.scrollTo(0, 0)
                }
              } else {
                setPhoneError('Enter Valid Phone Number')
                window.scrollTo(0, 0)
              }
            } else {
              setStateError('Enter Valid State')
              window.scrollTo(0, 0)
            }
          } else {
            setCityError('Enter Valid City')
            window.scrollTo(0, 0)
          }
        } else {
          setAddressError('Enter Valid Address')
          window.scrollTo(0, 0)
        }
      } else {
        setNameError('Enter you Name')
        window.scrollTo(0, 0)
      }
    } else {
      setEmailError('Enter Valid email')
      window.scrollTo(0, 0)
    }
  }
  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay))
  }
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }
  useEffect(() => {
    window.scrollTo(0, 0)

    dispatch(getCartDetailsFromStorage())

    if (order && orderSuccess) {
      setDisabled(false)
      cookies.remove('_cid', {
        path: '/',
      })
      if (!order.isPaid) {
        history.push(`/order/${order._id}/orderconfirm/cod`)
      } else {
        history.push(
          `/order/${order._id}/orderconfirm/${order.paymentResult.rid}`
        )
      }
    } else {
      loadScript('https://checkout.razorpay.com/v1/checkout.js')
    }
    if (guestSuccess && !orderSuccess) {
      setDisabled(true)
      if (user.pmethod == 'online') {
        var options = {
          //key: 'rzp_test_Uc74kK1ulMDQoS',
          key: 'rzp_live_04AG18531Cjx9B',
          amount: cart.totalPrice * 100,
          currency: 'INR',
          name: name,
          description: '99bookscart',
          order_id: user.presults.id,
          image:
            'https://cdn.shopify.com/s/files/1/0287/9612/5316/files/99bookscart_759b028b-6768-47b8-9d79-ee2cf0190e04_180x.png?v=1646232311',
          handler: function (response) {
            const paymentResult = {
              id: response.razorpay_payment_id,
              rid: response.razorpay_order_id,
              signature: response.razorpay_signature,
            }
            dispatch(
              createOrder({
                orderItems: cart.cartItems,
                shippingAddress: cart.shippingAddress,
                paymentMethod: user.pmethod,
                shippingMethod: 'Standard',
                itemPrice: cart.cartPrice,
                shippingPrice: cart.shippingPrice,
                taxPrice: cart.taxPrice,
                totalPrice: cart.totalPrice,
                codPrice: 0,
                isPaid: response.razorpay_order_id ? true : false,
                paidAt: response.razorpay_order_id ? Date.now() : '',
                paymentResult: paymentResult,
                cartId: cookies.get('_cid'),
              })
            )
            timeout(1000)
          },
          prefill: {
            name: name,
            email: email,
            contact: phone,
          },
          theme: {
            color: '#4267b2',
          },
        }

        const paymentObject = new window.Razorpay(options)
        paymentObject.open()
      } else {
        if (!order) {
          const paymentResult = {
            id: uuidv4(),
            rid: null,
            signature: null,
          }
          dispatch(
            createOrder({
              paymentMethod: 'cod',
              shippingMethod: 'Standard',
              discount: {},
              paymentResult: paymentResult,
              cartId: cookies.get('_cid'),
            })
          )
        }
      }
    }
    if (guestError) {
      alert(guestError)
      if (guestError.type == 'phone') {
        setPhoneError(guestError.data)
      }
      if (guestError.type == 'email') {
        setEmailError(guestError.data)
      }
    }
  }, [dispatch, guestSuccess, guestError, order, orderSuccess])

  const couponCodeHandler = (e) => {
    e.preventDefault()
    dispatch(saveDiscountCode(couponCode))
  }
  const showConfimration = (e) => {
    e.preventDefault()
    setShow(true)
  }
  const removeDiscount = (e) => {
    e.preventDefault()
    dispatch(removeDiscountFromCart())
  }

  const setPaymentMethodToStorage = (val) => {
    setPaymentMethod(val)
    dispatch(saveShippingMethodToStorage(val))
  }
  return (
    <>
      <Container style={{ minHeight: '750px' }}>
        <Row>
          <Col>
            <Link to={'/'}>
              <LazyLoadImage
                src='../../images/logo.png'
                className='checkout-logo'
                alt='99bookscart logo'
              ></LazyLoadImage>
            </Link>
          </Col>
          <Col>
            {' '}
            <div
              style={{
                float: 'right',
                marginTop: '14px',
                fontSize: '12px',
                fontWeight: 'bold',
                color: '#333',
              }}
            >
              +91901-915-8183
              <br />
              Mon-Sat 9:30am-6:30pm
            </div>
          </Col>
        </Row>

        <h1 className='cart-header mt-3'>Place Order</h1>
        <p className='text-center'>Become Most Intresting Person in Room</p>
        {cartLoading && !cartSuccess ? (
          <Loader></Loader>
        ) : (
          <>
            <Row>
              <Container style={{ padding: '8px', fontSize: '16px' }}>
                <Container>
                  <Typography variant='subtitle1'>
                    <strong>Order Details</strong>
                  </Typography>
                </Container>

                {cart.cartItems && cart.cartItems.length < 3 ? (
                  <Container>
                    {cart.cartItems.map((item, index) => (
                      <>
                        <Row>
                          <Col xs={7} style={{ fontSize: '14px' }}>
                            {item.name}
                          </Col>
                          <Col xs={3} style={{ fontSize: '12px' }}>
                            <strike style={{ color: 'red' }}>
                              &#8377;
                              {item.price &&
                                item.price.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                            </strike>
                          </Col>
                          <Col xs={2}>
                            &#8377;
                            {item.salePrice}
                          </Col>
                        </Row>
                      </>
                    ))}
                    <div className='order-sum'>
                      <Row>
                        <Col xs={7}>Cart Total</Col>

                        <Col xs={5}>
                          &#8377;
                          {cart.cartPrice &&
                            cart.cartPrice.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={7}> Delivery Charges:</Col>

                        <Col xs={5}>
                          &#8377;
                          {cart.shippingPrice &&
                            addDecimals(
                              cart.shippingPrice.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            )}
                        </Col>
                      </Row>

                      {cart.discountPrice > 0 && (
                        <>
                          <Row
                            style={{
                              fontSize: '16px',
                              fontWeight: 'bold',
                              color: '#fa4741',
                            }}
                          >
                            <Col xs={7}>Discount</Col>

                            <Col xs={5}>
                              -₹{' '}
                              {addDecimals(
                                cart.discountPrice.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              )}
                            </Col>
                          </Row>
                        </>
                      )}
                      <Row>
                        <Col xs={7}> Payable Total</Col>

                        <Col xs={5} style={{ fontSize: '21px' }}>
                          &#8377;{' '}
                          {cart.totalPrice &&
                            cart.totalPrice.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                        </Col>
                      </Row>
                    </div>
                  </Container>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      '& > :not(style)': {
                        m: 1,
                        width: '100%',
                        height: 65,
                      },
                    }}
                  >
                    <Paper elevation={3}>
                      <Row
                        className='text-center'
                        style={{ marginTop: '8px', fontSize: '14px' }}
                      >
                        <Col xs={6}>
                          <Link to='/cart'>View Cart </Link>
                          <br /> {cart.cartItems && cart.cartItems.length} Items
                        </Col>
                        <Col xs={6}>
                          Cart Total
                          <br />
                          <span className='productPrice'>
                            {' '}
                            &#8377;
                            {cart.cartPrice &&
                              cart.cartPrice.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            /-
                          </span>
                        </Col>
                      </Row>
                    </Paper>
                  </Box>
                )}
              </Container>
            </Row>
            <Row className='checkout-shipping-address'>
              {/* <Col xs={12} md={6}>
                <div style={{ float: 'left' }}>
                  <Typography variant='subtitle1'>
                    <strong>Shipping Address</strong>
                  </Typography>
                  <Typography variant='subtitle2' className='dark'>
                    {cart.shippingAddress && cart.shippingAddress.name}
                    <br />
                    {cart.shippingAddress && cart.shippingAddress.address},
                    {cart.shippingAddress && cart.shippingAddress.address2},
                    <br />
                    {cart.shippingAddress && cart.shippingAddress.city},
                    {cart.shippingAddress && cart.shippingAddress.state},
                    {cart.shippingAddress && cart.shippingAddress.postalCode}
                    <br />
                    {cart.shippingAddress && cart.shippingAddress.phone}{' '}
                  </Typography>
                  <p className='delivery-date'>
                    <FaTruck></FaTruck> Delivery by{' '}
                    {days[deliveryDate.getDay()]},{deliveryDate.getDate()}{' '}
                    {months[deliveryDate.getMonth()]}
                  </p>
                </div>
              </Col> */}
              <Col xs={12} md={5}>
                <div>Benefits</div>
                <Row style={{ marginBottom: '15px' }} className='savedText'>
                  <Col>
                    <div>
                      <FaCheckCircle></FaCheckCircle> &nbsp;You Saved &#8377;
                      {cart.cartItems &&
                        (
                          cart.cartItems.reduce(
                            (acc, item) => acc + item.price,
                            0
                          ) - cart.cartPrice
                        ).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{' '}
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <FaCoins></FaCoins> Earn {cart.cartPrice / 50} coins
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <FaBook></FaBook> Read Lead Succeed
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Typography variant='subtitle' className='dark'>
                    {/* <Typography variant='subtitle1'>
                      <strong>Total</strong>
                    </Typography> */}
                    <Typography variant='subtitle1'>
                      <strong>Shipping Address</strong>
                    </Typography>
                    <div>
                      {cart.shippingAddress && cart.shippingAddress.name}
                      <br />
                      {cart.shippingAddress && cart.shippingAddress.address},
                      {cart.shippingAddress && cart.shippingAddress.address2},
                      <br />
                      {cart.shippingAddress && cart.shippingAddress.city},
                      {cart.shippingAddress && cart.shippingAddress.state},
                      {cart.shippingAddress && cart.shippingAddress.postalCode}
                      <br />
                      {cart.shippingAddress && cart.shippingAddress.phone}{' '}
                    </div>
                    <Typography variant='subtitle1'>
                      <strong>Delivery Information</strong>
                    </Typography>
                    <div style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                      <strong>
                        {' '}
                        <FaTruck></FaTruck> Expected Delivery By{' '}
                        {days[deliveryDate.getDay()]},{deliveryDate.getDate()}{' '}
                        {months[deliveryDate.getMonth()]}
                      </strong>
                      <br />
                      <Container>
                        <Row>
                          <Col>
                            <LazyLoadImage
                              src='../images/del.png'
                              alt='delhivery logo'
                              width={'100px'}
                            />
                          </Col>
                          <Col>
                            <LazyLoadImage
                              src='../images/amaz.png'
                              alt='delhivery logo'
                              width={'120px'}
                            />
                          </Col>
                        </Row>
                      </Container>
                    </div>
                    {/*<Typography variant='subtitle1'>
                      <strong>Order Summary</strong>
                    </Typography>
                    <div
                      style={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                      }}
                    >
                      Cart Total: &#8377;
                      {cart.cartPrice > 0 &&
                        cart.cartPrice.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                    </div>
                    <div
                      style={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                      }}
                    >
                      Delivery Charges: &#8377;
                      {cart.shippingPrice &&
                        addDecimals(
                          cart.shippingPrice.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        )}
                      <br />
                    </div>
                    {cart.codPrice > 0 && (
                      <>
                        <div
                          style={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                          }}
                        >
                          COD Charges: &#8377;
                          {cart.codPrice &&
                            addDecimals(
                              cart.codPrice.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            )}
                        </div>
                      </>
                    )}{' '}
                    {cart.discountPrice > 0 && (
                      <>
                        <div
                          style={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            color: '#fa4741',
                          }}
                        >
                          Discount: ₹{' '}
                          {addDecimals(
                            cart.discountPrice.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          )}
                        </div>
                        <Link to='' onClick={removeDiscount}>
                          Remove Discount
                        </Link>
                      </>
                    )}
                    <div style={{ marginBottom: '22px', fontWeight: 'bold' }}>
                      Payable Total:
                      <span style={{ fontSize: '22px', fontWeight: 'bold' }}>
                        &#8377;{' '}
                        {cart.totalPrice &&
                          cart.totalPrice.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </span>
                    </div> */}
                    {/* {!isCodeA && cart.discountPrice == 0 && (
                      <Form onSubmit={couponCodeHandler}>
                        {couponCodeError && couponCodeError}
                        {discountCodeSuccess && discountCode.name}
                        <Row>
                          <Col>
                            <TextField
                              id='outlined-basic'
                              label='Coupon Code'
                              variant='outlined'
                              type='Text'
                              required
                              autoComplete='off'
                              value={couponCode}
                              onChange={(e) => setCouponCode(e.target.value)}
                              style={{
                                marginBottom: '10px',
                                float: 'right',
                                padding: '0px',
                              }}
                            />
                          </Col>
                          <Col>
                            <Button
                              type='submit'
                              variant='contained'
                              className='couponbutton'
                              disabled={isCodeApplied}
                              style={{ float: 'left' }}
                            >
                              Apply
                            </Button>
                          </Col>
                        </Row>
                      </Form>s
                    )} */}
                  </Typography>
                </Row>
              </Col>
              <Col xs={12} md={7}>
                <Row className='text-center mt-5'>
                  {/* <Typography variant='subtitle1' className='text-center mt-2'>
                    <strong>Payment Information</strong>
                  </Typography> */}
                  <FormContainer>
                    <form onSubmit={submitHandler} className='cart-form'>
                      <Form.Group>
                        {processingOrder && <Loader></Loader>}
                        <Row>
                          <Typography
                            variant='caption'
                            className='text-center'
                            style={{ color: '#333' }}
                          >
                            Pay using UPI, Wallets, Cards or Net Banking, etc.
                            (FREE & Secure)
                          </Typography>
                          <Button
                            className='ldp-variant2'
                            id='Online'
                            name='paymentMethod'
                            value='online'
                            checked='checked'
                            onClick={(e) => submitHandler(e)}
                          >
                            (₹0) Pay Online
                          </Button>
                          <div className='text-center'>
                            <LazyLoadImage
                              src='../images/razorpay.png'
                              alt='razorpay'
                              width={'80px'}
                            />
                            <LazyLoadImage
                              src='../images/norton.png'
                              alt='shiprocket logo'
                              width={'80px'}
                            />
                          </div>
                        </Row>

                        <Row>
                          <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
                            {codError && codError}
                          </div>
                          <Typography
                            variant='caption'
                            className='text-center'
                            style={{ color: '#333' }}
                          >
                            Customer needs to confirm all COD by paying ₹9.00
                          </Typography>
                          <Button
                            className='ldp-variant2'
                            id='COD'
                            name='paymentMethod'
                            value='cod'
                            onClick={(e) => submitHandler(e)}
                          >
                            (+₹99.00) Pay On Delivery
                          </Button>
                        </Row>
                      </Form.Group>
                      {/* <div
                        style={{
                          textAlign: 'center',
                          fontWeight: 'bold',
                          color: '#333',
                          textTransform: 'none',
                        }}
                      >
                        99bookscart since 2017
                      </div> */}

                      {/* <br />
                    <Button
                      type='submit'
                      variant='primary'
                      className='placeorder-bt'
                    >
                      Place Order
                    </Button> */}
                    </form>
                  </FormContainer>
                </Row>
              </Col>
            </Row>

            <Modal show={show} backdrop='static'>
              <Modal.Body>
                <Container style={{ padding: '35px' }}>
                  <h5>Pay On Delivery Order</h5>
                  <p>
                    Cart total: ₹
                    {cart.totalPrice && cart.totalPrice.toLocaleString()}
                    <br />
                    Pay On Delivery fees: ₹{99}
                    <br />
                    Order total: ₹
                    <span style={{ fontSize: '17px', fontWeight: 'bold' }}>
                      {cart.totalPrice &&
                        (cart.totalPrice + 99.0).toLocaleString()}
                    </span>
                  </p>
                  <Button
                    className='ldp-variant2'
                    id='COD'
                    name='paymentMethod'
                    value='cod'
                    onClick={(e) => submitHandler(e)}
                  >
                    Place Order
                  </Button>
                  <br />
                  <div onClick={() => setShow(false)}>Cancel & pay online</div>
                </Container>
              </Modal.Body>
            </Modal>
          </>
        )}
      </Container>
    </>
  )
}

export default ShippingScreen
