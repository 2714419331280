import React, { useState, useEffect, useRef } from 'react'
import { Form, Card, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import { saveShippingAddress } from '../actions/cartActions'
import CheckoutSteps from '../components/CheckoutSteps'
import OrderSummary from '../components/OrderSummary'
import { Link } from 'react-router-dom'
import { FaCheckCircle, FaTruck } from 'react-icons/fa'
import {
  userAddressList,
  deleteUserAddress,
  createGuestUserAddress,
} from '../actions/userActions'
import { getCartDetailsFromStorage } from '../actions/cartActions.js'
import Cookies from 'universal-cookie'
import { SHIPPING_STATE } from '../constants/orderConstants'
import { Autocomplete, TextField } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Loader from '../components/Loader'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Razorpay from 'razorpay'
import { v4 as uuidv4 } from 'uuid'
import {
  createOrder,
  payOrder,
  postOrderPayment,
} from '../actions/orderActions'
const cookies = new Cookies()
var days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]
var months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
const CheckoutScreen = ({ history }) => {
  const firstRender = useRef(true)

  const dispatch = useDispatch()

  const guestUser = useSelector((state) => state.guestUser)
  const { user, success: guestSuccess, error: guestError } = guestUser
  const userCart = useSelector((state) => state.userCart)
  const { cart, loading: cartLoading, success: cartSuccess } = userCart
  const [email, setEmail] = useState(cookies.get('_cfx_email'))
  const [name, setName] = useState(cookies.get('_cfx_name'))

  const [address, setAddress] = useState(cookies.get('_cfx_address'))
  const [address2, setAddress2] = useState(cookies.get('_cfx_address1'))
  const [city, setCity] = useState(cookies.get('_cfx_city'))
  const [postalCode, setPostalCode] = useState(cookies.get('_cfx_pin'))
  const [phone, setPhone] = useState(cookies.get('_cfx_phone'))
  const [state, setState] = useState(cookies.get('_cfx_state'))

  const [disable, setDisabled] = useState(false)
  const [selectedAddress, setSelectedAddress] = useState({})
  const [nameError, setNameError] = useState(null)
  const [addressError, setAddressError] = useState(null)
  const [cityError, setCityError] = useState(null)
  const [stateError, setStateError] = useState(null)
  const [emailError, setEmailError] = useState(null)
  const [phoneError, setPhoneError] = useState(null)
  const [zipError, setZipError] = useState(null)
  const [addNew, setAddNew] = useState(false)
  const orderCreate = useSelector((state) => state.orderCreate)
  const { order, success: orderSuccess, error } = orderCreate
  var date = new Date()
  var deliveryDate = new Date(date.setTime(date.getTime() + 7 * 86400000))
  // const [country, setCountry] = useState(shippingAddress.country)
  let country = 'India'
  var months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay))
  }
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }
  useEffect(() => {
    window.scrollTo(0, 0)

    dispatch(getCartDetailsFromStorage())

    if (order && orderSuccess) {
      setDisabled(false)
      cookies.remove('_cid', {
        path: '/',
      })
      if (!order.isPaid) {
        history.push(`/order/${order._id}/orderconfirm/cod`)
      } else {
        history.push(
          `/order/${order._id}/orderconfirm/${order.paymentResult.rid}`
        )
      }
    } else {
      loadScript('https://checkout.razorpay.com/v1/checkout.js')
    }
    if (guestSuccess && !orderSuccess) {
      setDisabled(true)
      if (user.pmethod == 'online') {
        //custom logic for pay online

        if (user.pmethod == 'online') {
          var amount = cart.totalPrice
        } else {
          var amount = 2900
        }

        //customer logic for pay online

        var options = {
          //key: 'rzp_test_Uc74kK1ulMDQoS',
          key: 'rzp_live_04AG18531Cjx9B',
          amount: amount * 100,
          currency: 'INR',
          name: name,
          description: '99bookscart',
          order_id: user.presults.id,
          image:
            'https://cdn.shopify.com/s/files/1/0287/9612/5316/files/99bookscart_759b028b-6768-47b8-9d79-ee2cf0190e04_180x.png?v=1646232311',
          handler: function (response) {
            const paymentResult = {
              id: response.razorpay_payment_id,
              rid: response.razorpay_order_id,
              signature: response.razorpay_signature,
            }
            dispatch(
              createOrder({
                orderItems: cart.cartItems,
                shippingAddress: cart.shippingAddress,
                paymentMethod: user.pmethod,
                shippingMethod: 'Standard',
                itemPrice: cart.cartPrice,
                shippingPrice: cart.shippingPrice,
                taxPrice: cart.taxPrice,
                totalPrice: cart.totalPrice,
                codPrice: 0,
                isPaid: response.razorpay_order_id ? true : false,
                paidAt: response.razorpay_order_id ? Date.now() : '',
                paymentResult: paymentResult,
                cartId: cookies.get('_cid'),
              })
            )
            timeout(1000)
          },
          prefill: {
            name: name,
            email: email,
            contact: phone,
          },
          theme: {
            color: '#4267b2',
          },
        }

        const paymentObject = new window.Razorpay(options)
        paymentObject.open()
      } else {
        if (!order) {
          const paymentResult = {
            id: uuidv4(),
            rid: null,
            signature: null,
          }
          dispatch(
            createOrder({
              paymentMethod: 'cod',
              shippingMethod: 'Standard',
              discount: {},
              paymentResult: paymentResult,
              cartId: cookies.get('_cid'),
            })
          )
        }
      }
    }
    if (guestError) {
      alert(guestError)
      if (guestError.type == 'phone') {
        setPhoneError(guestError.data)
      }
      if (guestError.type == 'email') {
        setEmailError(guestError.data)
      }
    }
  }, [dispatch, guestSuccess, guestError, order, orderSuccess])

  function isEmail(email) {
    var emailFormat = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
    if (email !== '' && email && email.match(emailFormat)) {
      return true
    }

    return false
  }
  const submitHandler = (e) => {
    e.preventDefault()
    setEmailError('')
    setNameError('')
    setAddressError('')
    setCityError('')
    setStateError('')
    setZipError('')
    if (isEmail(email)) {
      setEmailError('')
      if (name.length > 3) {
        if (address.length > 8) {
          if (city.length > 2) {
            if (state.length > 2) {
              var phonenoExp = /^\d{10}$/
              if (phonenoExp.test(phone)) {
                setPhoneError('')
                if (postalCode.length == 6) {
                  setZipError('')
                  dispatch(
                    createGuestUserAddress({
                      name,
                      address,
                      address2,
                      city,
                      postalCode,
                      state,
                      country,
                      phone,
                      email,
                      cartId: cookies.get('_cid'),
                      pmethod: e.target.value,
                    })
                  )
                } else {
                  setZipError('Enter Valid ZipCode')
                  window.scrollTo(0, 0)
                }
              } else {
                setPhoneError('Enter Valid Phone Number')
                window.scrollTo(0, 0)
              }
            } else {
              setStateError('Enter Valid State')
              window.scrollTo(0, 0)
            }
          } else {
            setCityError('Enter Valid City')
            window.scrollTo(0, 0)
          }
        } else {
          setAddressError('Enter Valid Address')
          window.scrollTo(0, 0)
        }
      } else {
        setNameError('Enter you Name')
        window.scrollTo(0, 0)
      }
    } else {
      setEmailError('Enter Valid email')
      window.scrollTo(0, 0)
    }
  }
  return (
    <Container>
      <Row>
        <Col>
          <Link to={'/'}>
            <LazyLoadImage
              src='../../images/logo.png'
              className='checkout-logo'
              alt='99bookscart logo'
            ></LazyLoadImage>
          </Link>
        </Col>
        <Col>
          {' '}
          <div
            style={{
              float: 'right',
              marginTop: '14px',
              fontSize: '12px',
              fontWeight: 'bold',
              color: '#333',
            }}
          >
            901-915-8183
            <br />
            Mon-Sat 9:30am-6:30pm
          </div>
        </Col>
      </Row>
      <div style={{ textAlign: 'center', marginBottom: '8px' }}>
        <div style={{ fontSize: '22px', padding: '8px' }}>
          <strong>Build Your Own Library</strong>
        </div>
      </div>
      <form className='cart-form2'>
        <Row>
          <Col sm={12} md={6}>
            <Typography variant='subtitle1'>
              <strong>Shipping Address</strong>
            </Typography>

            <TextField
              id='outlined-basic'
              label='Email'
              variant='outlined'
              required
              type='Email'
              autoComplete='off'
              error={emailError ? true : false}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{ width: '100%', marginBottom: '10px' }}
            />

            <TextField
              id='outlined-basic'
              label='Full Name'
              variant='outlined'
              required
              type='Text'
              autoComplete='off'
              error={nameError ? true : false}
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={{ width: '100%', marginBottom: '10px' }}
            />

            <TextField
              id='outlined-basic'
              label='Address'
              variant='outlined'
              required
              type='Text'
              autoComplete='off'
              error={addressError ? true : false}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              style={{ width: '100%', marginBottom: '10px' }}
            />
            <TextField
              id='outlined-basic'
              label='Apartment, Suite, etc (Optional)'
              variant='outlined'
              type='Text'
              autoComplete='off'
              value={address2}
              onChange={(e) => setAddress2(e.target.value)}
              style={{ width: '100%', marginBottom: '10px' }}
            />

            <TextField
              id='outlined-basic'
              label='City'
              variant='outlined'
              type='Text'
              required
              autoComplete='off'
              error={cityError ? true : false}
              value={city}
              onChange={(e) => setCity(e.target.value)}
              style={{ width: '48%', marginBottom: '10px' }}
            />

            <TextField
              id='outlined-basic'
              label='Zip Code'
              variant='outlined'
              type='Number'
              required
              autoComplete='off'
              error={zipError ? true : false}
              // helperText={zipError ? zipError : ''}
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              style={{
                width: '48%',
                marginBottom: '10px',
                marginLeft: '10px',
              }}
            />

            <Autocomplete
              options={SHIPPING_STATE}
              getOptionLabel={(option) => option}
              value={state}
              onChange={(event, newValue) => {
                setState(newValue)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='State'
                  variant='outlined'
                  fullWidth
                  required
                  error={stateError ? true : false}
                />
              )}
            />

            <TextField
              id='outlined-basic'
              label='Phone Number'
              variant='outlined'
              type='phone'
              margin='normal'
              autoComplete='off'
              // hidden={phone && phone.length == 10 ? true : false}
              error={phoneError ? true : false}
              required
              helperText={phoneError ? phoneError : ''}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              style={{ width: '100%' }}
            />

            {/* <Row
              style={{ marginLeft: 'auto', marginRight: 'auto' }}
              className='text-center'
            >
              <Col>
                <Button
                  type='submit'
                  variant='contained'
                  className='theme-button'
                  disabled={disable}
                  fullWidth
                  sx={{ mt: 2, mb: 2 }}
                >
                  Continue
                </Button>
              </Col>
            </Row>
            <Row>
              <div style={{ fontSize: '20px' }} className='text-center'>
                READ LEAD SUCCEED
              </div>
            </Row> */}
            {/* <Row>
              <Col
                style={{ marginLeft: 'auto', marginRight: 'auto' }}
                className='text-center'
              >
                <Typography variant='body2' xs={{ mb: 2 }}>
                  <small>Already have an Account?</small>{' '}
                  <Link to='/login'>Login</Link> or{' '}
                  <Link to='/register'>Create Account</Link>
                </Typography>
                <br />
                <Typography
                  variant='body1'
                  xs={{ mb: 2 }}
                  style={{ color: '#005D6E' }}
                >
                  <a href='https://www.99bookscart.com/pages/return-refund'>
                    Return & Refund Policy
                  </a>
                  <br />
                  <a href='https://www.99bookscart.com/contact-us'>
                    Contact us
                  </a>
                  <br />
                  <Link
                    to='/pages/terms-and-condition'
                    aria-label='Terms and Condition'
                  >
                    Terms and Condition
                  </Link>{' '}
                  <br />
                  <Link to='/pages/privacy-policy' aria-label='Privacy Policy'>
                    Privacy Policy
                  </Link>{' '}
                  <br />
                </Typography>
              </Col>
            </Row> */}
          </Col>
          <Col sm={12} md={6}>
            <Container
              className='text-center mt-3'
              style={{ border: '1px solid #566573', borderRadius: '6px' }}
            >
              <Typography variant='subtitle1'>
                <strong>
                  <FaTruck></FaTruck> Delivery Information
                </strong>
              </Typography>
              <div style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                <strong>
                  {' '}
                  Expected Delivery {city && `to ${city}`} By{' '}
                  {days[deliveryDate.getDay()]},{deliveryDate.getDate()}{' '}
                  {months[deliveryDate.getMonth()]}, 2024
                </strong>
                <br />
                <Container>
                  <Row>
                    <Col>
                      <LazyLoadImage
                        src='../images/del.png'
                        alt='delhivery logo'
                        width={'100px'}
                      />
                    </Col>
                    <Col>
                      <LazyLoadImage
                        src='../images/amaz.png'
                        alt='delhivery logo'
                        width={'120px'}
                      />
                    </Col>
                  </Row>
                </Container>
              </div>
            </Container>
            <Container>
              <Row>
                <OrderSummary cart={cart}></OrderSummary>
              </Row>
            </Container>
            <Row className='text-center mt-3'>
              <Typography variant='subtitle1' className='text-center mt-2'>
                <strong>Payment Information</strong>
              </Typography>
              <Container>
                <Form.Group>
                  {/* {processingOrder && <Loader></Loader>} */}
                  <Row>
                    <div style={{ fontWeight: 'bold' }}>
                      Pay Online and get 5 FREE Self Help ebooks
                    </div>
                    <Typography
                      variant='caption'
                      className='text-center'
                      style={{ color: '#333' }}
                    >
                      Pay using UPI, Card or Net Banking etc. (FREE & Secure)
                    </Typography>
                    <Button
                      className='ldp-variant2'
                      id='Online'
                      name='paymentMethod'
                      value='online'
                      checked='checked'
                      onClick={(e) => submitHandler(e)}
                    >
                      (₹0) Pay Online
                    </Button>
                    <div className='text-center'>
                      <LazyLoadImage
                        src='../images/razorpay.png'
                        alt='razorpay'
                        width={'80px'}
                      />
                      <LazyLoadImage
                        src='../images/norton.png'
                        alt='shiprocket logo'
                        width={'80px'}
                      />
                    </div>
                  </Row>

                  <Row>
                    <Typography
                      variant='caption'
                      className='text-center'
                      style={{ color: '#333' }}
                    >
                      Customer needs to confirm all COD by paying ₹9.00
                    </Typography>
                    <Button
                      className='ldp-variant2'
                      id='COD'
                      name='paymentMethod'
                      value='cod'
                      onClick={(e) => submitHandler(e)}
                    >
                      (+₹99.00) Pay On Delivery
                    </Button>
                  </Row>
                </Form.Group>
                {/* <div
                        style={{
                          textAlign: 'center',
                          fontWeight: 'bold',
                          color: '#333',
                          textTransform: 'none',
                        }}
                      >
                        99bookscart since 2017
                      </div> */}

                {/* <br />
                    <Button
                      type='submit'
                      variant='primary'
                      className='placeorder-bt'
                    >
                      Place Order
                    </Button> */}
              </Container>
            </Row>
          </Col>
        </Row>
        <br />
        <br />
      </form>
    </Container>
  )
}

export default CheckoutScreen
