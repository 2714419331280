import { React, useState } from 'react'
import { Route, useLocation, Link } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../actions/userActions'

import { FaUserAlt, FaBookReader, FaShoppingCart } from 'react-icons/fa'

import { FaSearch } from 'react-icons/fa'
import 'react-pro-sidebar/dist/css/styles.css'
import { useHistory } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import SearchBox from '../components/SearchBox'
import { Input } from '@mui/base'
const Header = ({}) => {
  const history = useHistory()

  const dispatch = useDispatch()
  const [menuCollapse, setMenuCollapse] = useState(false)

  const menuIconClick = () => {
    //condition checking to change state from true to false and vice versa
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true)
  }
  const location = useLocation()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const cart = useSelector((state) => state.cart)

  const logoutHandler = () => {
    dispatch(logout())
    history.push('/')
  }
  var url = ''
  if (location.pathname.indexOf('us') > 0) {
    url = '/app/profile/us/billing'
  } else {
    url = '/app/profile/billing'
  }
  return (
    <header>
      {location.pathname.indexOf('99bookscart-surprise-box') > 0 ||
      location.pathname.indexOf('BookscartBox1/surprise-box-15-books') > 0 ||
      location.pathname.indexOf('ai-curated-box') > 0 ||
      location.pathname.indexOf('plots/en/read') > 0 ||
      location.pathname.indexOf('app/') > 0 ||
      location.pathname.indexOf('sell-books') > 0 ||
      location.pathname.indexOf('web-stories/') > 0 ||
      location.pathname.indexOf('checkout/shipping') > 0 ||
      location.pathname.indexOf('checkout') > 0 ||
      location.pathname.indexOf('self-help-books') > 0 ||
      location.pathname.indexOf('admin') > 0 ? (
        ''
      ) : (
        <>
          {location.pathname.indexOf('checkout') > 0 ||
          location.pathname.indexOf('BookscartBox1') > 0 ||
          location.pathname.indexOf('register') > 0 ||
          // location.pathname.indexOf('plots') > 0 ||
          location.pathname.indexOf('online-book-fair') > 0
            ? ''
            : location.pathname.indexOf('ai-curated-box') < 0 && (
                <div className='header-top'>
                  <Link
                    to='/book/BookscartBox1/surprise-box-15-books'
                    className='header-link'
                  >
                    Surprise Box 15 Books for ₹999/-
                  </Link>
                </div>
              )}
          {
            <>
              {location.pathname.indexOf('checkout') > 0 && false ? (
                ''
              ) : (
                <>
                  <Container>
                    {location.pathname.indexOf('blog') > 0 ? (
                      <span style={{ fontSize: '22px' }}>
                        <br />
                        99bookscart
                      </span>
                    ) : (
                      <Navbar
                        expand='lg'
                        collapseOnSelect
                        className='customer-nav'
                      >
                        <div style={{ width: '100%' }}>
                          <div style={{ float: 'left' }}>
                            <Link to='/'>
                              {location.pathname.indexOf('blog') > 0 ? (
                                <span style={{ fontSize: '22px' }}>
                                  <br />
                                  99bookscart
                                </span>
                              ) : (
                                <img
                                  src='https://s3-books-images.s3.amazonaws.com/ba0fc97f-47f7-473f-84aa-258d21a4b407.webp'
                                  className='siteLogo-app'
                                  alt='99bookscart logo'
                                  loading='lazy'
                                />
                              )}
                            </Link>
                          </div>

                          <div style={{ float: 'right' }}>
                            {/* <div className='onlyDesktop'>
                          <Input type='text' placeholder='Search' />
                          <FaSearch className='search-icon' />
                        </div> */}

                            <Link to='/search' name='search'>
                              <FaSearch
                                className='profile-icons'
                                aria-label='search'
                              ></FaSearch>
                            </Link>
                            <Link to='/cart' name='cart'>
                              <FaShoppingCart
                                className='profile-icons'
                                aria-label='cart'
                              />
                            </Link>

                            <Link to='/profile' aria-label='profile'>
                              <FaUserAlt
                                className='profile-icons'
                                aria-label='profile'
                              ></FaUserAlt>
                            </Link>
                          </div>
                        </div>
                      </Navbar>
                    )}
                  </Container>
                  {location.pathname.indexOf('seller') > 0 ||
                  location.pathname.indexOf('profile') > 0 ||
                  location.pathname.indexOf('login') > 0 ||
                  location.pathname.indexOf('sell') > 0 ||
                  location.pathname.indexOf('cart') > 0 ||
                  location.pathname.indexOf('checkout') > 0 ||
                  location.pathname.indexOf('plots') > 0 ||
                  location.pathname.indexOf('BookscartBox1') > 0 ||
                  location.pathname.indexOf('online-book-fair') > 0 ||
                  location.pathname.indexOf('register') > 0 ||
                  location.pathname.indexOf('book') > 0 ||
                  location.pathname.indexOf('blog') > 0 ? (
                    // location.pathname.indexOf('blog') > 0 && (
                    //   <Container>
                    //     <div className='hmenu'>
                    //       <Link
                    //         to='/book/BookscartBox1/surprise-box-15-books'
                    //         className='hmenu-item'
                    //       >
                    //         Best Second Hand Books
                    //       </Link>
                    //       <Link
                    //         to='/blog/view/65417a90c3cc925b98266fe1/best-books-on-stock-market-for-beginners-2023'
                    //         className='hmenu-item'
                    //       >
                    //         Best Trading Books
                    //       </Link>
                    //       <Link
                    //         to='/blog/view/65417e025607aa3a7c73cd11/best-books-on-human-psychology-2023'
                    //         className='hmenu-item'
                    //       >
                    //         Best Psychology Books
                    //       </Link>
                    //       <Link
                    //         to='/blog/view/65418aee83a1ad4460a392a6/best-books-on-human-relationships-2023'
                    //         className='hmenu-item'
                    //       >
                    //         Best Human Relationships Books
                    //       </Link>
                    //       {/* <Link to='/sell-books' className='hmenu-item'>
                    //         Sell Books
                    //       </Link>
                    //       <Link to='/category/only-99' className='hmenu-item'>
                    //         Only ₹99
                    //       </Link>
                    //       <Link
                    //         to='/book/BookscartBox1/surprise-box-15-books'
                    //         className='hmenu-item'
                    //       >
                    //         Surprise Box
                    //       </Link> */}
                    //     </div>
                    //   </Container>
                    // )
                    ''
                  ) : (
                    <Container>
                      <div className='hmenu'>
                        <Link to='/online-book-fair' className='hmenu-item'>
                          Online Book Fair
                        </Link>
                        <Link
                          to='/book/BookscartBox1/surprise-box-15-books'
                          className='hmenu-item'
                        >
                          Surprise Box
                        </Link>
                        <Link to='/category/only-99' className='hmenu-item'>
                          Only ₹99
                        </Link>
                        {/* <Link to='/fiction-books' className='hmenu-item'>
                          Fiction
                        </Link> */}
                        <Link to='/non-fiction-books' className='hmenu-item'>
                          Non Fiction
                        </Link>

                        <Link to='/sell-books' className='hmenu-item'>
                          Sell Books
                        </Link>
                      </div>
                    </Container>
                  )}
                </>
              )}
            </>
          }
        </>
      )}
    </header>
  )
}

export default Header
